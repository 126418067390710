<template>
  <div class="one">
    <!-- 左侧操作 -->
    <div class="left">
      <img class="logo" src="@/assets/logo-w.png" alt="" />
      <div
        class="shell"
        :class="left.tsColor == 'text' ? 'tsClass1' : ''"
        @click="leftOperation('text')"
      >
        <img src="@/assets/wenzi.png" alt="" />
        <p>文字</p>
      </div>
      <div
        class="shell"
        :class="left.tsColor == 'img' ? 'tsClass1' : ''"
        @click="leftOperation('img')"
      >
        <img src="@/assets/tupian.png" alt="" />
        <p>图片</p>
      </div>
    </div>
    <!-- 左侧操作 - 文字 -->
    <transition name="el-fade-in-linear">
      <div class="left_text" v-if="left.tsColor == 'text'">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-edit"
          @click="addText"
          >添加文字</el-button
        >
        <el-button
          type="primary"
          size="small"
          icon="el-icon-upload2"
          @click="changeZindexHandle('up')"
          >上移层级</el-button
        >
        <el-button
          type="primary"
          size="small"
          icon="el-icon-download"
          @click="changeZindexHandle('down')"
          >下移层级</el-button
        >
        <div class="left_text_shell">
          <div class="text_left">字体颜色</div>
          <div class="text_right">
            <el-color-picker
              size="small"
              v-model="left.text_color"
              @change="setUpText('fill')"
            ></el-color-picker>
          </div>
        </div>
        <div class="left_text_shell">
          <div class="text_left">字体大小</div>
          <div class="text_right">
            <el-input-number
              v-model="left.text_fontSize"
              size="small"
              :min="12"
              :max="100"
              label="请输入字体大小"
              @change="setUpText('fontSize')"
            ></el-input-number>
          </div>
        </div>
        <div class="left_text_shell">
          <div class="text_left">行间距</div>
          <div class="text_right">
            <el-slider v-model="left.text_h" :show-tooltip="false" @change="setUpText('lineHeight')"></el-slider>
          </div>
        </div>
        <div class="left_text_shell">
          <div class="text_left">字间距</div>
          <div class="text_right">
            <el-slider v-model="left.text_w" :show-tooltip="false" @change="setUpText('charSpacing')"></el-slider>
          </div>
        </div>
      </div>
    </transition>
    <!-- 左侧操作 - 图片 -->
    <transition name="el-fade-in-linear">
      <div class="left_img" v-if="left.tsColor == 'img'">
        <div class="left_img_top">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-edit"
            @click="addText"
            >添加文字</el-button
          >
          <el-button
            type="primary"
            size="small"
            icon="el-icon-upload2"
            @click="changeZindexHandle('up')"
            >上移层级</el-button
          >
          <el-button
            type="primary"
            size="small"
            icon="el-icon-download"
            @click="changeZindexHandle('down')"
            >下移层级</el-button
          >
        </div>
        <p class="title">插图</p>
        <div class="left_img_bottom_1">
          <el-upload
            class="img-el-upload upload-demo upload-btn"
            :on-change="addImg"
            :before-upload="beforeAvatarUpload2"
            :http-request="$requestMine"
            :show-file-list="false"
            action
            accept="image/png, image/gif, image/jpg, image/jpeg"
          >
            <el-button type="primary" size="mini" icon="el-icon-plus">添加插图</el-button>
          </el-upload>
        </div>
        <p class="title">背景图</p>
        <div class="left_img_bottom_2">
          <el-upload
            class="img-el-upload upload-demo upload-btn"
            :on-change="toggerBackHandle"
            :before-upload="$beforeAvatarUpload"
            :http-request="$requestMine"
            :show-file-list="false"
            action
            accept="image/png, image/gif, image/jpg, image/jpeg"
          >
            <el-button type="primary" size="mini">选择/替换背景</el-button>
          </el-upload>
          <div class="left_text_shell">
            <div class="text_left">背景颜色</div>
            <div class="text_right">
              <el-color-picker
                size="small"
                v-model="left.img_background"
                @change="backColorChangeHandle"
              ></el-color-picker>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- 画布 -->
    <canvas id="imageEffectCanvas" ref="imageEffectCanvas"></canvas>
    <!-- 右侧 -->
    <div class="right_sz">
      <el-form ref="templateInfo" :model="templateInfo" label-width="76px">
        <el-form-item label="模板名称">
          <el-input
            v-model="templateInfo.templateName"
            size="small"
            placeholder="请输入模板名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="招生类型">
          <el-select
            v-model="templateInfo.templateTypeId"
            size="small"
            placeholder="请选择招生类型"
          >
            <el-option
              v-for="item in template_type_data"
              :key="item.templateTypeId"
              :label="item.typeName"
              :value="item.templateTypeId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="模板宽" class="wh">
          <el-input
            v-model="templateInfo.templateWidth"
            @change="setwh"
            size="small"
            @keyup.native="
              templateInfo.templateWidth = zF.positiveInteger(
                templateInfo.templateWidth
              )
            "
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="模板高" class="wh">
          <el-input
            v-model="templateInfo.templateHeight"
             @change="setwh"
            size="small"
            @keyup.native="
              templateInfo.templateHeight = zF.positiveInteger(
                templateInfo.templateHeight
              )
            "
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <!-- 保存 - 重置 -->
    <div class="dcz">
      <template v-if="selectType == 'yw'">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-delete"
          @click="handleDelete"
          >删除图层</el-button
        >
        <el-button
          type="primary"
          size="small"
          icon="el-icon-refresh-left"
          @click="resetCanvas"
          >重置</el-button
        >
        <el-button
          type="primary"
          size="small"
          icon="el-icon-picture-outline"
          @click="handleSave(2)"
          >保存暂不发布</el-button
        >
        <el-button
          type="primary"
          size="small"
          icon="el-icon-picture-outline"
          @click="handleSave(1)"
          >发布</el-button
        >
      </template>
      <template v-if="selectType == 'jg'">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-delete"
          @click="handleDelete"
          >删除图层</el-button
        >
        <el-button
          type="primary"
          size="small"
          icon="el-icon-refresh-left"
          @click="resetCanvas"
          >重置</el-button
        >
        <!-- <el-button
          type="primary"
          size="small"
          icon="el-icon-s-claim"
          @click="preservation"
          >保存</el-button
        > -->
        <el-button
          type="primary"
          size="small"
          icon="el-icon-picture-outline"
          @click="exportCanvas"
          >保存并下载</el-button
        >
      </template>
    </div>
  </div>
</template>
<script>
// 初始化画布控制器
fabric.Object.prototype.set({
  cornerStrokeColor: "#ff4555",
  cornerColor: "#ff4555",
  cornerStyle: "circle",
  cornerSize: 4,
  borderScaleFactor: 1,
  borderColor: "#ff4555",
  hasControls: true,
  // 禁止对象平移缩放
  // lockMovementX: true,
  // lockMovementY: true,
  // lockRotation: true,
  // lockScalingFlip: true,
  // lockScalingX: true,
  // lockScalingY: true,
  // lockSkewingX: true,
  // lockSkewingY: true,
  // lockSkewingY: true
});
// 画布对象
let imageEffectCanvas = "";
export default {
  name: "one",
  components: {},
  data() {
    return {
      // 模板id
      templateId: "",
      // 从机构跳转-jg/从运维跳转-yw
      selectType: "",
      // 机构id
      compTemplateId:"",
      // 上传图片的宽
      imgHeight: "",
      // 上传图片的高
      imgWidth: "",
      // 左侧操作数据
      left: {
        // 左侧选中的样式
        tsColor: "",
        // 字体 - 颜色
        text_color: "#000000",
        // 字体大小
        text_fontSize: 14,
        // 行间距
        text_h: 20,
        // 字间距
        text_w: 0,
        // 插图列表
        img_illustrationlist: [],
        // 背景图列表
        img_backgroundlist: [],
        // 背景颜色
        img_background: "",
      },
      // 模板基本信息 - 名称/类型/尺寸
      templateInfo: {
        templateName: "", // 模板名称
        templateTypeId: "", // 模板类型
        templateWidth: "375", // 模板宽度
        templateHeight: "750", // 模板高度
      },
      // 模板类型 - 下拉数据
      template_type_data: [],
      // 文字的默认样式
      activeObjectData: {
        type: "textbox",
        text: "双击编辑文字",
        fontSize: 16,
        left: 100,
        top: 400,
        width: 200,
        fill: "#136546",
        textBackgroundColor: "rgba(0,0,0,0)",
        opacity: 1,
        stroke: "#ffffff",
        strokeWidth: 0,
        background: "#7ED321",
        fontWeight: "normal",
        fontStyle: "normal",
        underline: false,
        linethrough: false,
        overline: false,
        textAlign: "left",
        lineHeight: 1,
        charSpacing: 1,
        fontFamily: "hyzktjjkt",
        rotate: 0,
        selectable: true,
      },
    };
  },
  created() {
    this.selectType = this.$route.query.type;
    this.templateId = this.$route.query.id || "";
    this.compTemplateId = this.$route.query.compTemplateId || "";
    // 像后台传递的入参数
    this.get_Template_type();
  },
  mounted() {
    this.initImageEffectCanvas(this.$route.query.id);
    this.aaa();
  },
  watch: {},
  computed: {},
  methods: {
    aaa(){
      let mycanvas = document.getElementById("gird");
      console.log(mycanvas)
    },
    // 获取模板类型下拉数据
    get_Template_type() {
      this.$post("/design/run/template/getTemplateTypeList").then((res) => {
        this.template_type_data = res.data;
      });
    },
    // 左侧操作
    leftOperation(ccc) {
      this.left.tsColor = ccc;
    },
    // 初始化画布
    initImageEffectCanvas(id) {
      imageEffectCanvas = new fabric.Canvas("imageEffectCanvas", {
        width: this.templateInfo.templateWidth,
        height: this.templateInfo.templateHeight,
        selection: false,
        // backgroundColor: "#ffffff",
      });
      // 获取模板详情
      if (id) {
        let backstageData = {
          templateId: id,
        };
        let url = "";
        if (this.selectType == "yw") {
          url = "/design/run/template/getTemplateInfo";
        }
        if (this.selectType == "jg") {
          url = "/design/run/comp/template/getTemplateInfo";
          backstageData.compTemplateId = this.compTemplateId
        }
        this.$post(url, backstageData).then((res) => {
          this.templateInfo.templateName = res.data.desTemplate.templateName;
          this.templateInfo.templateTypeId =
            res.data.desTemplate.templateTypeId;
          this.templateInfo.templateWidth = res.data.desTemplate.templateWidth;
          this.templateInfo.templateHeight =
            res.data.desTemplate.templateHeight;
          this.templateContentLs = res.data.desTemplateContent.templateContent;
          imageEffectCanvas.loadFromJSON(
            res.data.desTemplateContent.templateContent
          );
          this.setwh();
        });
      }
      imageEffectCanvas.preserveObjectStacking = true;
    },
     
    // 更改画布大小
    setwh() {
      imageEffectCanvas.setWidth(this.templateInfo.templateWidth); 
      imageEffectCanvas.setHeight(this.templateInfo.templateHeight); ; 
    },
    // 添加文字
    addText() {
      //通过最大行高计算高度,并删除多余文字,多出文字..表示,三个会换行
      let Shape = new fabric.Textbox(
        this.activeObjectData.text || "",
        this.activeObjectData
      );
      imageEffectCanvas.add(Shape).setActiveObject(Shape);
    },
    // 文字设置
    setUpText(type) {
      let sx = "";
      // 颜色
      if(type == 'fill'){
        sx = this.left.text_color;
      }
      // 大小
      if(type == 'fontSize'){
        sx = this.left.text_fontSize;
      }
      // 行间距
      if(type == 'lineHeight'){
        sx = this.left.text_h;
      }
      // 字间距
      if(type == 'charSpacing'){
        sx = this.left.text_w;
      }
      imageEffectCanvas.getActiveObject().set(type, sx);
      imageEffectCanvas.renderAll();
    },
    // 添加插图
    addImg(res) {
      let fileType = res.raw.name.slice(
        res.raw.name.lastIndexOf(".") + 1,
        res.raw.name.length
      );
      let formData = new FormData();
      formData.append("file", res.raw);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      formData.append("isPublic ", true);
      let that = this;
      that.$Postformat("/sys/upload", formData).then((ret) => {
        let imgId = "";
        var data = [
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
        ];
        for (var i = 0; i < 20; i++) {
          imgId += data[Math.floor(Math.random() * 16)];
        }
        let imgNode = new Image();
        imgNode.setAttribute("crossOrigin", "anonymous");
        imgNode.id = imgId;
        imgNode.src = ret.data.fileURL;
        setTimeout(() => {
          let imgInstance = new fabric.Image(imgNode, {
            left: 0,
            top: 0,
          });
          imageEffectCanvas.add(imgInstance);
        }, 520);
        // let image = imageEffectCanvas.getActiveObject();
        // if (image && image.type == "image") {
        //   // image.setAttribute("crossOrigin", "anonymous");
        //   image.setSrc(ret.data.fileURL, function () {
        //     image.set("width", that.imgWidth);
        //     image.set("height", that.imgHeight);
        //     imageEffectCanvas.renderAll();
        //   });
        // } else {
        //   let imgNode = new Image();
        //   imgNode.setAttribute("crossOrigin", "anonymous");
        //   imgNode.id = imgId;
        //   imgNode.src = ret.data.fileURL;
        //   setTimeout(() => {
        //     let imgInstance = new fabric.Image(imgNode, {
        //       left: 0,
        //       top: 0,
        //     });
        //     imageEffectCanvas.add(imgInstance);
        //   }, 520);
        // }
      });
    },
    // 上传图片的校验/获取上传图片的宽高
    beforeAvatarUpload2(file) {
      let that = this;
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          file.type
        ) === -1
      ) {
        that.$message.error("请上传正确的图片格式");
        return false;
      }
      var reader = new FileReader();
      reader.onload = function (event) {
        var txt = event.target.result;
        var img = document.createElement("img");
        img.src = txt;
        img.onload = function () {
          that.imgHeight = img.width;
          that.imgWidth = img.height;
        };
      };
      reader.readAsDataURL(file);
      return isLt2M;
    },
    // 切换背景颜色
    backColorChangeHandle() {
      imageEffectCanvas.setBackgroundImage();
      imageEffectCanvas.setBackgroundColor(this.left.img_background);
      imageEffectCanvas.renderAll(); // 重新渲染画布
    },
    // 切换背景图
    toggerBackHandle(res) {
      let fileType = res.raw.name.slice(
        res.raw.name.lastIndexOf(".") + 1,
        res.raw.name.length
      );
      let formData = new FormData();
      formData.append("file", res.raw);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      formData.append("isPublic ", true);
      let that = this;
      that.$Postformat("/sys/upload", formData).then((ret) => {
        imageEffectCanvas.setBackgroundColor("#ffffff");
        this.adfds = ret.data.fileURL;
        let Shape;
        // (解决跨域图片加载)
        const image = new Image();
        image.setAttribute("crossOrigin", "anonymous");
        image.src = ret.data.fileURL;
        image.onload = function () {
          Shape = new fabric.Image(image, {
            // 通过scale来设置图片大小，这里设置和画布一样大
            scaleX: imageEffectCanvas.width / image.width,
            scaleY: imageEffectCanvas.height / image.height,
          });
          imageEffectCanvas.setBackgroundImage(
            Shape,
            imageEffectCanvas.renderAll.bind(imageEffectCanvas)
          ); // setBackgroundImage 方法设置画布背景
          imageEffectCanvas.renderAll(); //重新渲染画布
        };
      });
    },
    // 层级改变事件
    changeZindexHandle(type) {
      let level = imageEffectCanvas.getActiveObject(); // 获取当前被选中的元素
      if (type == "up") {
        imageEffectCanvas.bringForward(level); // 上移
      } else {
        imageEffectCanvas.sendBackwards(level); // 下移
      }
      imageEffectCanvas.renderAll(); //重新渲染
    },
    // 删除 - 元素
    handleDelete(type) {
      let activeObject = imageEffectCanvas.getActiveObject(); // 获取当前被选中的元素
      if (activeObject) {
        imageEffectCanvas.remove(activeObject); //删除元素
      }
    },
    // 重置画布
    resetCanvas() {
      imageEffectCanvas.clear();
    },
    // 机构/运维 - 下载图片
    exportCanvas() {
      const dataURL = imageEffectCanvas.toDataURL({
        width: imageEffectCanvas.width,
        height: imageEffectCanvas.height,
        left: 0,
        top: 0,
        format: "png",
      });
      const link = document.createElement("a");
      link.download = "canvas.png";
      link.href = dataURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      if (this.selectType == "jg") {
        let mycanvas = document.getElementById("imageEffectCanvas");
        mycanvas.setAttribute("crossOrigin", "anonymous");
        let base64Data = mycanvas.toDataURL("image/jpeg", 1.0);
        let str1 = base64Data.substring(
          base64Data.indexOf(","),
          base64Data.length
        );
        // 传后台传递的入参数
        let backstageData = {
          directUse: true,
          desTemplate: {
            templateName: this.templateInfo.templateName, // 模板名称
            templateTypeId: this.templateInfo.templateTypeId, // 模板类型id
            templateWidth: this.templateInfo.templateWidth, // 模板宽
            templateHeight: this.templateInfo.templateHeight, // 模板高
          },
          desTemplateContent: {
            templateContent: JSON.stringify(imageEffectCanvas), // 模板json串
          },
          templateImgBase64: str1.substr(1), // 模板生成的base64地址
        };
        if (this.templateId) {
          backstageData.desTemplate.templateId = this.templateId;
          backstageData.desTemplateContent.templateId = this.templateId;
        }
        if (this.compTemplateId) {
          backstageData.compTemplateId = this.compTemplateId;
        }
        if(this.templateContentLs !=  JSON.stringify(imageEffectCanvas)){
          backstageData.directUse = false;
        } else {
          backstageData.directUse = true;
        }
        this.$post(
          this.compTemplateId ? "/design/run/comp/template/modifyTemplate":"/design/run/comp/template/addTemplate",
          backstageData
        ).then((res) => {
          this.$post("/design/run/comp/template/downloadCount", {
            templateId: this.templateId,
            compTemplateId:res.data.compTemplateId
          }).then((res) => {
            this.$router.back()
          });
        });
        
      }
    },
    // 机构 - 保存
    preservation() {
      let mycanvas = document.getElementById("imageEffectCanvas");
      mycanvas.setAttribute("crossOrigin", "anonymous");
      let base64Data = mycanvas.toDataURL("image/jpeg", 1.0);
      let str1 = base64Data.substring(
        base64Data.indexOf(","),
        base64Data.length
      );
      // 传后台传递的入参数
      let backstageData = {
        desTemplate: {
          templateName: this.templateInfo.templateName, // 模板名称
          templateTypeId: this.templateInfo.templateTypeId, // 模板类型id
          templateWidth: this.templateInfo.templateWidth, // 模板宽
          templateHeight: this.templateInfo.templateHeight, // 模板高
        },
        desTemplateContent: {
          templateContent: JSON.stringify(imageEffectCanvas), // 模板json串
        },
        templateImgBase64: str1.substr(1), // 模板生成的base64地址
      };
      if (this.templateId) {
        backstageData.desTemplate.templateId = this.templateId;
        backstageData.desTemplateContent.templateId = this.templateId;
      }
      if (this.compTemplateId) {
        backstageData.compTemplateId = this.compTemplateId;
      }
      if(this.templateContentLs !=  JSON.stringify(imageEffectCanvas)){
        backstageData.directUse = false;
      } else {
        backstageData.directUse = true;
      }
      this.$post(
        this.compTemplateId ? "/design/run/comp/template/modifyTemplate":"/design/run/comp/template/addTemplate",
        backstageData
      ).then((res) => {
        if (res.status == "0") {

          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.$router.back()
        }
      });
    },
    // 运维 - 保存暂不发布-2/发布-1
    handleSave(types) {
      if (!this.templateInfo.templateName) {
        this.$message({
          message: "请输入模板名称",
          type: "warning",
        });
        return false;
      }
      if (!this.templateInfo.templateTypeId) {
        this.$message({
          message: "请输选择模板类型",
          type: "warning",
        });
        return false;
      }
      if (!this.templateInfo.templateWidth) {
        this.$message({
          message: "请输入模板宽度",
          type: "warning",
        });
        return false;
      }
      if (!this.templateInfo.templateHeight) {
        this.$message({
          message: "请输入模板高度",
          type: "warning",
        });
        return false;
      }
      let mycanvas = document.getElementById("imageEffectCanvas");
      mycanvas.setAttribute("crossOrigin", "anonymous");
      let base64Data = mycanvas.toDataURL("image/jpeg", 1.0);
      let str1 = base64Data.substring(
        base64Data.indexOf(","),
        base64Data.length
      );
      // 像后台传递的入参数
      let backstageData = {
        desTemplate: {
          templateName: this.templateInfo.templateName, // 模板名称
          templateTypeId: this.templateInfo.templateTypeId, // 模板类型id
          templateWidth: this.templateInfo.templateWidth, // 模板宽
          templateHeight: this.templateInfo.templateHeight, // 模板高
          sellState: types == 1 ? 1 : 0, // 模板上架状态
        },
        desTemplateContent: {
          templateContent: JSON.stringify(imageEffectCanvas), // 模板json串
        },
        templateImgBase64: str1.substr(1), // 模板生成的base64地址
      };
      if (this.templateId) {
        backstageData.desTemplate.templateId = this.templateId;
        backstageData.desTemplateContent.templateId = this.templateId;
      }
      // 在判断是新增还是编辑
      if (this.templateId) {
        this.$post("/design/run/template/modifyTemplate", backstageData).then(
          (res) => {
            if (res.status == "0") {
              if (types == 1) {
                this.$message({
                  message: "发布成功",
                  type: "success",
                });
              } else {
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
              }
              this.$router.back()
            }
          }
        );
      } else {
        this.$post("/design/run/template/addTemplate", backstageData).then(
          (res) => {
            if (res.status == "0") {
              if (types == 1) {
                this.$message({
                  message: "发布成功",
                  type: "success",
                });
              } else {
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
              }
              this.$router.back()
            }
          }
        );
      }
    },
    // 封装blob对象
    dataURItoBlob(base64Data) {
      var byteString;
      if (base64Data.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(base64Data.split(",")[1]);
      else byteString = unescape(base64Data.split(",")[1]);
      var mimeString = base64Data.split(",")[0].split(":")[1].split(";")[0];
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    },
  },
};
</script>
<style lang="less">
.one {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #ebebeb;
  .canvas-container {
    position: absolute !important;
    top: 10%;
    left: 40%;
    background: #fff;
    border-radius: 8px;
  }
  #imageEffectCanvas {
    border-radius: 8px;
  }
  .left {
    width: 60px;
    height: 100%;
    overflow: hidden;
    background: #f0f3f7;
    .logo {
      width: 36px;
      display: block;
      margin: auto;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .shell {
      width: 50px;
      margin-bottom: 20px;
      cursor: pointer;
      padding-left: 4px;
      img {
        width: 26px;
        display: block;
        margin: auto;
      }
      p {
        font-size: 12px;
        text-align: center;
      }
    }
  }
  .left_text {
    width: 260px;
    height: 100%;
    position: absolute;
    background: #f9f9f9;
    top: 0;
    left: 60px;
    padding: 10px 0 0 20px;
    .el-button {
      margin-left: 10px !important;
      margin-bottom: 10px !important;
      float: left;
    }
    .el-button:nth-child(2) {
      clear: both;
    }
    .left_text_shell {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      margin-top: 20px;
      .text_left {
        width: 30%;
        float: left;
        margin-right: 4%;
        font-size: 14px;
        align-items: center;
      }
      .text_right {
        width: 66%;
        align-items: center;
        .el-slider,
        .el-input-number {
          width: 84%;
        }
      }
    }
  }
  .left_img {
    width: 260px;
    height: 100%;
    position: absolute;
    background: #f9f9f9;
    top: 0;
    left: 60px;
    padding-top: 10px;
    .title {
      text-align: center;
      font-size: 16px;
      margin-bottom: 2%;
      color: #409eff;
      clear: both;
    }
    .left_img_top {
      padding-left: 20px;
      .el-button {
        margin-left: 10px !important;
        margin-bottom: 10px !important;
        float: left;
      }
      .el-button:nth-child(2) {
        clear: both;
      }
      // .left_text_shell {
      //   width: 100%;
      //   display: flex;
      //   align-items: center;
      //   padding-left: 10px;
      //   .text_left {
      //     width: 29%;
      //     float: left;
      //     font-size: 14px;
      //     align-items: center;
      //   }
      //   .text_right {
      //     width: 66%;
      //     align-items: center;
      //     .el-slider,
      //     .el-input-number {
      //       width: 84%;
      //     }
      //   }
      // }
    }
    .left_img_bottom_1 {
      padding-left: 20px;
      .el-button {
        margin-left: 10px !important;
        margin-bottom: 10px !important;
        float: left;
      }
      .upload-btn {
        .el-upload {
          width: 107px;
          height: 43px !important;
          border: none !important;
          button {
            width: 100%;
          }
        }
      }
      // height: 30%;
      // margin-bottom: 4%;
      // overflow-y: scroll;
      // .bimg {
      //   width: 20%;
      //   height: 50px;
      //   margin-left: 4%;
      //   margin-bottom: 10px;
      //   border-radius: 4px;
      //   cursor: pointer;
      //   transition: all 0.5s;
      //   &:hover {
      //     transform: scale(1.05);
      //   }
      // }
    }
    .left_img_bottom_2 {
      padding-left: 20px;
      .el-button {
        margin-left: 10px !important;
        margin-bottom: 10px !important;
        float: left;
      }
      .upload-btn {
        .el-upload {
          width: 107px;
          height: 43px !important;
          border: none !important;
          button {
            width: 100%;
          }
        }
      }
      .left_text_shell {
        display: flex;
        align-items: center;
        padding-left: 10px;
        .text_left {
          float: left;
          font-size: 14px;
          align-items: center;
        }
        .text_right {
          margin-left: 10px;
          align-items: center;
          .el-slider,
          .el-input-number {
            width: 84%;
          }
        }
      }
      // height: 49%;
      // overflow-y: scroll;
      // .bimg {
      //   width: 44%;
      //   height: 180px;
      //   margin-left: 4%;
      //   margin-bottom: 10px;
      //   border-radius: 4px;
      //   cursor: pointer;
      //   transition: all 0.5s;
      //   &:hover {
      //     transform: scale(1.05);
      //   }
      // }
    }
  }
  .dcz {
    position: fixed;
    bottom: 20px;
    right: 40px;
    margin-left: -78px;
  }
  .right_sz {
    width: 700px;
    position: absolute;
    background: #f9f9f9;
    top: 0;
    left: 50%;
    margin-left: -400px;
    padding: 10px 20px 0 20px;
    border-radius: 0 0 8px 8px;
    .el-form-item {
      width: 28%;
      float: left;
      margin-bottom: 10px;
    }
    .wh {
      width: 22%;
      float: left;
    }
  }
}
.tsClass1 {
  border-left: 4px solid #409eff;
  width: 46px !important;
}
</style>